.checkbox__customLabel {
  color: $gray-600;
  font-size: $font-size-sm;
  font-weight: 400; // $font-weight-normal;
  line-height: initial;
  &::before,
  &::after {
    top: 0;
  }
}

.checkbox__customLabel--centered {
  &::before,
  &::after {
    margin-top: -0.5rem;
    top: 55%;
  }
}
