@import "node_modules/@fuze/bootstrap-theme/scss/bootstrap-variables/bootstrap-variables";

.empty-list {
  color: $gray-80;
  font-size: $font-size-lg;
  margin: -4rem auto;
  text-align: center;
  width: 60%;
  img {
    margin: 3rem auto 2rem auto;
  }
  strong {
    font-size: $font-size-xl;
    font-weight: $font-weight-bold;
  }
  p {
    margin-bottom: 1rem;
  }
}
