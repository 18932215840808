@import "./form-mixins";

.form-field {
  margin-bottom: 1rem;
}

.fuze-select__control {
  min-height: 40px;
}

.search-input-with-icon {
  width: 100%;

  .icon-search {
    &::before {
      font-size: 1.7rem;
      left: 0.55rem;
      position: absolute;
      top: 0.45rem;
      z-index: 1;
    }

    input {
      font-family: $font-family-sans-serif;
      padding-left: 2.5rem;
      border-color: $gray-70;

      // css-tricks.com/almanac/selectors/p/placeholder/
      &::placeholder {
        color: $gray-40;
      }
    }
  }
}

.fixed-width-field {
  max-width: $fixed-width-field-short;

  .modal-body & {
    max-width: 100%;
  }
}

.fixed-width-field--long {
  max-width: $fixed-width-field-long;
}

.spaced-label {
  strong {
    margin-right: 0.25em;
  }
}

.percent-input {
  &:after {
    content: "%";
  }
}

.percent-input__box {
  max-width: 1.3rem;
  border: none;
  padding: 0;
  border-bottom: 2px solid;
}
