@import "node_modules/@fuze/bootstrap-theme/scss/bootstrap-variables/bootstrap-variables";
@import "../../../styles/mixins";

$purple-header-height: 2.5rem;

#sidebar-hub {
  background-color: $gray-050;
  font-size: $fuze-font-size-base;
  list-style: none outside none;
  padding: 0;
  width: 200px;
  margin-bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  top: $purple-header-height;
  height: calc(100vh - #{$purple-header-height});
  overflow-y: auto;

  .icon-locations {
    font-size: 18px;
  }
  .icon-billing-activity {
    font-size: 17px;
  }

  > li {
    position: relative;

    a,
    button {
      background-color: inherit;
      border: 0 none;
      color: inherit;
      display: flex;
      font-weight: $fuze-font-weight-light;
      padding: 0.8rem 0.75rem 0.8rem 0.5rem;
      text-align: left;
      width: 100%;

      &.active {
        background-color: $gray-10;
        font-weight: $fuze-font-weight-regular;
        border-left: 0.25rem solid $purple-light;
        padding-left: 0.25rem;
      }

      &:not(.active):hover {
        background-color: $gray-10;
      }

      &.focus-visible:focus {
        outline: 1px solid $teal-dark;
        outline-offset: -1px;
      }

      .side-bar__icon {
        flex: 0 0 2.25em;
        font-size: 1rem;
        line-height: 1.25;
        text-align: center;
      }

      .side-bar__label {
        flex: 1;
        word-break: break-word;
      }

      .submenu-caret {
        color: $gray-50;
        font-size: $fuze-font-size-xsmall;
        font-weight: bold;
        line-height: 1.75;
        margin-left: 0.5em;

        &.icon-arrow-up {
          color: $teal;
        }
      }
    }

    // NESTED SUBMENU ITEMS (contained within expand/collapse section)
    .submenu {
      overflow: hidden;
      list-style-type: none;
      padding-left: 0;
      background-color: white;
      // @include optional-reduced-motion;

      li {
        position: relative;

        a {
          &.active {
            background-color: white;
            font-weight: $fuze-font-weight-light;
            border-left-color: transparent;

            .link-text {
              border-bottom: 1px solid $purple;
            }
          }

          .link-text {
            margin-left: 2.25rem;
            padding-bottom: 0.125rem;
          }
        }
      }

      &.is-animated li {
        // @include optional-reduced-motion;
      }

      &.open-menu {
        li {
          transition-timing-function: ease-in;
        }
      }
      &.closed-menu {
        li {
          line-height: 0;
          transition-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
        }
      }
    }
  }
}

/*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
  We are limiting the implementation to ONLY the sidebar for now
  Will apply to other elements slowly over time
*/
.js-focus-visible #sidebar-hub :focus:not(.focus-visible) {
  outline: none;
}
