@import "node_modules/@fuze/bootstrap-theme/scss/bootstrap-variables/bootstrap-variables";

.modal-switch-organization .modal-content {
  max-width: 500px;

  .spinner {
    border-width: 0.2rem;
    display: block;
    margin: 0 auto;

    height: 2rem;
    width: 2rem;
  }

  .list-item {
    border-top: 1px solid #dee2e6;
    button.btn {
      display: block;
      width: 100%;
      color: inherit;
      padding: 0.3rem;
      background: none;
      border: none;
      text-align: left;
      text-transform: none;
      font-weight: 300; // $font-weight-light;
      &:focus,
      &:hover {
        background-color: $gray-10;
      }
    }
    &.stripped {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .modal-footer {
    padding-bottom: 1rem;
  }
}
