.top-nav-bar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  width: 100%;
  @include gradient-dark-purple-to-purple();
}

.top-nav-bar--home {
  display: flex;
  justify-content: left;
  padding-left: 1rem;
}

.top-nav-bar--tenant-switcher {
  display: flex;
  width: 15%;
  color: $fuze-gray-5;
}

.top-nav-bar--tenant-switcher .dropdown {
  padding: 0 0.5rem;
  &.show {
    background-color: $fuze-purple-primary;
    border-radius: 5px;
  }
}

.tenant-switcher {
  align-items: center;
  display: flex;
  font-size: $fuze-font-size-base;
  padding: 8px;
  justify-content: center;
}

.tenant-switcher--button {
  background-color: transparent;
  font-weight: $fuze-font-weight-default;
  text-transform: initial;
}

.tenant-switcher__icon {
  bottom: initial;
  font-size: $fuze-font-size-xsmall;
  padding-left: 0.5rem;
}

.top-nav-bar--links {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.top-nav-bar--user-help {
  background: $purple-dark;
  width: 3%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-nav-bar--user-help--icon {
  color: #cdbde8;
}
