.cell-with-header {
  border: solid thin $gray_10;
  min-width: 100%;
  min-height: 100%;
}

.cell-with-header__header {
  background: $gray_5;
  min-width: 100%;
  padding: 8px;
  font-weight: bold;
}

.cell-with-header__value {
  padding: 8px;
  min-width: 100%;
  overflow: auto;
  max-height: 140px;
}
